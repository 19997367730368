@tailwind base;
@tailwind components;
@tailwind utilities;

.card {
  background-color: #263a35;
}

.cardValue {
  font-size: 8vh;
  font-weight: 400;
  font-family: "Jockey One", sans-serif;
}

.cardTitle {
  font-size: 3vh;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.cardLongText {
  font-size: 4vh;
  font-weight: 400;
  font-family: "Jockey One", sans-serif;
}

.cardTableTitle {
  font-size: 2vh;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.cardTableValue {
  font-size: 3vh;
  font-weight: 300;
  font-family: "Jockey One", sans-serif;
}

.cardTable {
  padding-left: 2vh;
  padding-right: 2vh;
  background-color: #263a35;
}

.backPenningTable {
  display: "flex";
  flex-direction: "row";
  width: "100%";
  height: "100%";
  padding-left: "5vh";
  padding-right: "5vh";
  margin-bottom: "1vh";
}

.backPenningValue {
  padding-left: 2vh;
  padding-right: 2vh;
  font-size: 10vh;
  font-weight: 300;
  font-family: "Jockey One", sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

html {
  @apply h-full bg-gray-50;
}

body,
#__next {
  @apply h-full overflow-hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input[type="number"].hide-stepper::-webkit-outer-spin-button,
input[type="number"].hide-stepper::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"].hide-stepper {
  -moz-appearance: textfield;
}

@media (prefers-color-scheme: dark) {
  /* html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  } */
}

/* Overrides for the form elements within the datagrid. This removes the tailwind forms styling. */
#portal input:focus {
  /* Reset the lineheight */
  @apply shadow-none ring-0 border-0 outline-none;
  line-height: unset;
}
#portal textarea:focus {
  /* Reset the lineheight */
  @apply shadow-none ring-0 border-0 outline-none;
  line-height: unset;
}

/* Popper Arrow */
#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: currentColor;
}

#arrow {
  top: -4px;
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

::selection {
  background-color: #79dba0;
  color: #0e1513;
}

/* Text Boxes on the display board */
.screen-display-box--container {
  container-type: size;
  container-name: screen-display-box--container;
}
/* Table on the display Board  */
.display-table {
  container-type: size;
  container-name: display-table;
}
.display-table-row {
  container-type: size;
  container-name: display-table-row;
}
.display-table-item {
  container-type: inline-size;
  container-name: display-table-item;
}

/* 720p */
/* Same as any other CSS Lowest At the top */
@media (min-width: 720px) {
  .screen-display-box--container,
  .screen-display-box--main-header {
    gap: 4px;
  }
  .screen-display-box--container {
    padding: 4px;
  }
  .display-table {
    font-size: 24px;
  }
  .display-table-header {
    text-transform: uppercase;
    font-size: 12px;
    height: 60px;
  }
  .display-table-row {
    height: 60px;
  }
  .display-table-item {
    font-size: 22px;
  }
  .screen-display-box--utils-container {
  }
  .screen-display-box--utils-container img {
    height: 40px;
    width: 40px;
  }
  .screen-display-box--utils-time {
    font-size: 40px;
  }
  .holding-template img {
    height: 80px;
    width: 80px;
  }

  .back-penning-table {
    .display-table-row {
      height: 160px;
    }
    .display-table-item {
      font-size: 120px;
    }
  }

  /* Very small height for the display board */
  @container screen-display-box--container (width > 100px) {
    .screen-display-box--value {
      font-size: 80px;
    }
    .screen-display-box--title {
      font-size: 24px;
    }
  }

  /* Very small height for the display board */
  @container screen-display-box--container (width > 400px) {
    .screen-display-box--value {
      font-size: 80px;
    }
    .screen-display-box--title {
      font-size: 28px;
    }
  }

  @container screen-display-box--container (height > 300px) and (width > 500px) {
    .screen-display-box--value {
      font-size: 220px;
    }
    .screen-display-box--title {
      font-size: 60px;
    }
  }
  @container screen-display-box--container (height < 80px) and (width > 1500px) {
    .screen-display-box--value {
      font-size: 32px;
    }
    .screen-display-box--title {
      font-size: 24px;
    }
  }
}
/* 2K */
/* Same as any other CSS Lowest At the top */
@media (min-width: 1920px) {
  .screen-display-box--container,
  .screen-display-box--main-header {
    gap: 8px;
  }
  .screen-display-box--container {
    padding: 8px;
  }
  .display-table {
    font-size: 24px;
  }
  .display-table-header {
    text-transform: uppercase;
    font-size: 20px;
    height: 80px;
  }
  .display-table-row {
    height: 100px;
  }
  .display-table-item {
    font-size: 42px;
  }
  .screen-display-box--utils-container {
    padding: 12px;
    font-size: 18px;
  }
  .screen-display-box--utils-container img {
    height: 58px;
    width: 58px;
  }
  .screen-display-box--utils-time {
    font-size: 52px;
  }
  .holding-template img {
    height: 80px;
    width: 80px;
  }

  .back-penning-table {
    .display-table-row {
      height: 160px;
    }
    .display-table-item {
      font-size: 120px;
    }
  }

  /* Very small height for the display board */
  @container screen-display-box--container (width > 100px) {
    .screen-display-box--value {
      font-size: 130px;
    }
    .screen-display-box--title {
      font-size: 24px;
    }
  }

  /* Very small height for the display board */
  @container screen-display-box--container (width > 400px) {
    .screen-display-box--utils-container img {
      height: 58px;
      width: 58px;
    }
    .screen-display-box--value {
      font-size: 108px;
    }
    .screen-display-box--title {
      font-size: 24px;
    }
  }

  @container screen-display-box--container (height > 300px) and (width > 500px) {
    .screen-display-box--value {
      font-size: 220px;
    }
    .screen-display-box--title {
      font-size: 60px;
    }
  }
  @container screen-display-box--container (height < 80px) and (width > 1500px) {
    .screen-display-box--value {
      font-size: 32px;
    }
    .screen-display-box--title {
      font-size: 24px;
    }
  }
}

/* 4K */
/* Same as any other CSS Lowest At the top */
@media (min-width: 3840px) {
  .screen-display-box--container,
  .screen-display-box--main-header {
    gap: 20px;
  }
  .screen-display-box--container {
    padding: 18px;
  }
  .display-table {
    font-size: 24px;
  }
  .display-table-header {
    text-transform: uppercase;
    font-size: 42px;
    height: 140px;
  }
  .display-table-row {
    height: 200px;
  }
  .display-table-item {
    font-size: 102px;
  }
  .screen-display-box--utils-container {
    padding: 12px;
    font-size: 18px;
  }
  .screen-display-box--utils-container img {
    height: 132px;
    width: 132px;
  }
  .screen-display-box--utils-time {
    font-size: 114px;
  }

  .back-penning-table {
    .display-table-row {
      height: 330px;
    }
    .display-table-item {
      font-size: 225px;
    }
  }

  /* Very small height for the display board */
  @container screen-display-box--container (width < 650px) {
    .screen-display-box--value {
      font-size: 250px;
    }
    .screen-display-box--title {
      font-size: 72px;
    }
  }
  @container screen-display-box--container (width > 650px) {
    .screen-display-box--utils-container img {
      height: 130px;
      width: 130px;
    }
    .screen-display-box--value {
      font-size: 192px;
    }
    .screen-display-box--title {
      font-size: 64px;
    }
  }
  @container screen-display-box--container (width > 600px) {
    .screen-display-box--value {
      font-size: 236px;
    }
    .screen-display-box--title {
      font-size: 80px;
    }
  }
  /* Marquee */
  @container screen-display-box--container (width > 2000px ) and (height < 600px) {
    .screen-display-box--value {
      font-size: 160px;
    }
    .screen-display-box--title {
      font-size: 80px;
    }
  }
  /* CARD Display */
  @container screen-display-box--container (width > 1000px ) and (height > 600px) {
    .screen-display-box--value {
      font-size: 600px;
    }
    .screen-display-box--title {
      font-size: 80px;
    }
  }
  @container screen-display-box--container (height < 150px) and (width > 600px) {
    .screen-display-box--value {
      font-size: 72px;
    }
    .screen-display-box--title {
      font-size: 42px;
    }
  }
  .holding-template img {
    height: 120px;
    width: 120px;
  }
}

/* Remove pagination from payouts page */
#payouts-page .hiERUN,
#pagination-first-page,
#pagination-last-page {
  display: none;
}

progress {
  padding: 0;
  border-radius: 7px;
  width: 100%;
  height: 10px;
}
progress::-webkit-progress-bar {
  background-color: rgba(128, 128, 128, 0.541);
  border-radius: 7px;
}
progress::-webkit-progress-value {
  background-color: #4ba36a;
  border-radius: 7px;
}
